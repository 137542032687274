import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import dynamic from 'next/dynamic';
import React from 'react';
import styles from './style.module.scss';
const TextExpand = dynamic(() => import('@/components/common/TextExpand'));

interface CategoryDescriptionProps {
  items?: any;
  slug?: any;
}
const CategoryDescription: React.FC<CategoryDescriptionProps> = ({
  items,
  slug,
}) => {
  return (
    <>
      <section className={styles.sofasetsection}>
        <div className={styles.sofasetinner}>
          <div className="container-1240 ">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.sofasetheading}>
                  {items.title === 'Top Picks For You' ? (
                    <p className={styles.heading}>{items?.title}</p>
                  ) : (
                    <h1 className={styles.catHeading}>{items?.title}</h1>
                  )}
                  {items?.description && (
                    <TextExpand
                      content={items.description}
                      isHtml
                      className={styles.catDescription}
                    />
                  )}
                </div>
              </div>
            </div>

            <div className={styles.sofasetcontent}>
              <div
                className={`wdRow justify-content-center wdRow-cols-2 wdRow-cols-sm-2 wdRow-cols-md-3 wdRow-cols-lg-${items?.length > 9 ? items?.length / 2 : 6} wdgx-1`}
                style={{ textAlign: 'center' }}
              >
                {items?.items?.map((list: any, index: number) => (
                  <div className="wdCol" key={index}>
                    <CustomLink
                      href={list.href}
                      className={styles.sofasetcard}
                      prefetch={true}
                    >
                      <figure>
                        <WsStaticImage
                          src={list.image}
                          alt={list?.title || list?.title}
                          title={list?.title}
                          //// loading="lazy"
                          quality={90}
                          width={198}
                          height={170}
                          key={`${slug}-CategoryDescription-'${index}`}
                          unoptimized={false}
                        />
                      </figure>
                      <p className={styles.sofasettext}>{list?.title}</p>
                    </CustomLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryDescription;
