import WsStaticImage from '@/components/common/WsStaticImage';
import { AppDispatch } from '@/store';
import { getRequestedSettings } from '@/store/features/commonSlice';
import {
  hideLogin,
  showLoginModal,
  showSignUp,
} from '@/store/features/loginSlice';
import { getSettingByKeyValue } from '@/utils/common';
import { getCookie } from 'cookies-next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.scss';
import CustomLink from '@/components/CustomLink';
// import Image from 'next/image';

interface OfferProps {
  isMobile: any;
  items?: any;
}

const Offer: React.FC<OfferProps> = ({ isMobile, items }) => {
  const requestedSettings = useSelector(getRequestedSettings);
  const imgVer = getSettingByKeyValue(requestedSettings, 'config_imgvrsn');
  const dispatch = useDispatch<AppDispatch>();
  const openOffer = (e: any) => {
    if (items.href) {
      window.open(items.href, '_blank');
      e.preventDefault();
      return;
    } else {
      e.preventDefault();
      if (!getCookie('woodenstreetUserAuthToken')) {
        dispatch(showLoginModal());
        dispatch(hideLogin());
        dispatch(showSignUp());
      }
    }
  };
  return (
    <section className={styles.seasonsection}>
      <div className="container-1600">
        <div className={styles.seasonimg}>
          <CustomLink href={items?.href ?? '#'} onClick={openOffer}>
            <WsStaticImage
              width={410}
              key={items?.image}
              height={105}
              quality={100}
              unoptimized={true}
              alt={items?.alt}
              src={`${isMobile ? items?.mimage : items?.image}?v=${imgVer}`}
            />
          </CustomLink>
        </div>
      </div>
    </section>
  );
};

export default Offer;
