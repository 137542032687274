import WsStaticImage from '@/components/common/WsStaticImage';
import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import CustomLink from '@/components/CustomLink';

interface PopularSofaProps {
  items?: any;
  isMobile: any;
}

const PopularSofa: React.FC<PopularSofaProps> = ({ items, isMobile }) => {
  const tabList = items.items;
  const [activeTab, setActiveTab] = useState(0);
  const [firstHalf, setFirstHalf] = useState([]);
  const [secondHalf, setSecondHalf] = useState([]);
  // const chunks = chunkArray(items?.items || [], 2);

  const handleTabChange = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    setActiveTab(index);
  };
  useEffect(() => {
    const items = tabList[activeTab]?.items || [];
    const midpoint = Math.ceil(items.length / 2);
    const firstHalf = items.slice(0, midpoint);
    const secondHalf = items.slice(midpoint);
    setFirstHalf(firstHalf);
    setSecondHalf(secondHalf);
  }, [activeTab]);

  return (
    <>
      <section className={styles.populrsofasection}>
        <div className={styles.populrsofainner}>
          <div className="container-1600">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.populrsofaheading}>
                  {items?.htag ? (
                    <div className={styles['title']}>{items.title}</div>
                  ) : (
                    <h2 className={styles['title']}>{items.title}</h2>
                  )}
                  <p className={styles.subtitle}>
                    Design Your Home Decor, Your Way
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.populrsofacontent}>
              <div className="wdRow ">
                <div className="wdCol-lg-12">
                  <div className={styles.tabstop}>
                    <ul className="sofa-tabs">
                      {tabList?.map((item: any, index: any) => (
                        <li key={index}>
                          <a
                            className={`tab-btn ${activeTab == index ? 'active' : ''}`}
                            onClick={(e) => handleTabChange(e, index)}
                          >
                            {item.tabName}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="tab-content">
                {!isMobile ? (
                  <div className="wdRow wdgx-3">
                    {tabList[activeTab]?.items?.map(
                      (childList: any, index: number) => (
                        <div
                          className="wdCol-lg-3 wdCol-md-3 wdCol-sm-6 wdCol-12"
                          key={index}
                        >
                          <CustomLink
                            href={childList?.href}
                            className={styles.populrsofacard}
                          >
                            <div className={styles.populrsofaimg}>
                              <WsStaticImage
                                src={childList.image}
                                alt={`Online Furniture ${childList.alt}`}
                                key={childList.image}
                                width={500}
                                height={500}
                                quality={75}
                                data-was-processed="true"
                              />
                            </div>

                            <div className={styles.populrsofatext}>
                              <p>{childList.title}</p>
                              {childList.label &&
                                (typeof childList.label === 'object' ? (
                                  <p>
                                    {childList.label.price && (
                                      <>
                                        {childList.label.price}{' '}
                                        <del className={styles.offprice}>
                                          {childList.label.mrp}
                                        </del>
                                        <span className={styles.discountoff}>
                                          {childList.label.discount}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                ) : (
                                  <p> {childList.label}</p>
                                ))}
                            </div>
                          </CustomLink>
                        </div>
                      )
                    )}
                  </div>
                ) : (
                  <div className={styles.row}>
                    <div>
                      {firstHalf?.map((childList: any, index: number) => (
                        <div className={styles.col} key={index}>
                          <CustomLink
                            href={childList?.href}
                            className={styles.populrsofacard}
                          >
                            <div className={styles.populrsofaimg}>
                              <WsStaticImage
                                src={childList.image}
                                alt={`Online Furniture ${childList.alt}`}
                                key={childList.image}
                                width={500}
                                height={500}
                                quality={75}
                                data-was-processed="true"
                              />
                            </div>

                            <div className={styles.populrsofatext}>
                              <p>{childList.title}</p>
                              {childList.label &&
                                (typeof childList.label === 'object' ? (
                                  <p className={styles.price}>
                                    {childList.label.price && (
                                      <>
                                        {childList.label.price}{' '}
                                        <del className={styles.offprice}>
                                          {childList.label.mrp}
                                        </del>
                                        <span className={styles.discountoff}>
                                          {childList.label.discount}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                ) : (
                                  <p className={styles.price}>
                                    {childList.label}
                                  </p>
                                ))}
                            </div>
                          </CustomLink>
                        </div>
                      ))}
                    </div>
                    <div>
                      {secondHalf?.map((childList: any, index: number) => (
                        <div className={styles.col} key={index}>
                          <CustomLink
                            href={childList?.href}
                            className={styles.populrsofacard}
                          >
                            <div className={styles.populrsofaimg}>
                              <WsStaticImage
                                src={childList.image}
                                alt={`Online Furniture ${childList.alt}`}
                                key={childList.image}
                                width={500}
                                height={500}
                                quality={75}
                                data-was-processed="true"
                              />
                            </div>

                            <div className={styles.populrsofatext}>
                              <p>{childList.title}</p>
                              {childList.label &&
                                (typeof childList.label === 'object' ? (
                                  <p className={styles.price}>
                                    {childList.label.price && (
                                      <>
                                        {childList.label.price}{' '}
                                        <del className={styles.offprice}>
                                          {childList.label.mrp}
                                        </del>
                                        <span className={styles.discountoff}>
                                          {childList.label.discount}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                ) : (
                                  <p className={styles.price}>
                                    {childList.label}
                                  </p>
                                ))}
                            </div>
                          </CustomLink>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {tabList[activeTab]?.moreView && (
                  <div className="wdRow">
                    <div className="wdCol-lg-12">
                      <div className={styles.viewsofabutton}>
                        <CustomLink
                          href={tabList[activeTab]?.moreView}
                          className="btn secondary-btn"
                        >
                          View More
                        </CustomLink>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PopularSofa;
