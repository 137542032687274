// components/CustomImage.tsx
import React, { useState } from 'react';
import Image, { ImageProps } from 'next/image';

interface WsImageProps extends ImageProps {
  alt: string;
  isS3path?: boolean;
}

const WsFillImage: React.FC<WsImageProps> = ({
  isS3path = true,
  src,
  alt,
  ...props
}) => {
  const s3path = process.env.NEXT_PUBLIC_IMAGE_S3_BUCKET_BASE_URL;

  const [fullPath, setFullPath] = useState(isS3path ? `${s3path}${src}` : src);

  const handleError = () => {
    console.error(`Failed to load image: ${fullPath}`);
    setFullPath('/images/error.jpg');
  };
  return (
    <Image
      src={fullPath}
      alt={alt}
      {...props}
      // sizes="100vw"
      quality={100}
      fill={true}
      onError={handleError}
      style={{ height: '100%', width: '100%' }}
    />
  );
};

export default WsFillImage;
