import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './style.module.scss';

import WsStaticImage from '@/components/common/WsStaticImage';
import { Navigation } from 'swiper/modules';
import CustomLink from '@/components/CustomLink';

interface SeatingEssentialProps {
  items: any;
}
const SeatingEssential: React.FC<SeatingEssentialProps> = ({ items }) => {
  return (
    <>
      <section className={styles.shopsofasizesection} style={items.style}>
        <div className="container-1600">
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.shopsofasizeheading}>
                {items?.htag ? (
                  <div className={styles['title']}>{items.title}</div>
                ) : (
                  <h2 className={styles['title']}>{items.title}</h2>
                )}
                <p className={styles['sub-title']}>{items?.description}</p>
              </div>
            </div>
          </div>
          <div className={styles.shopsofasizecontant}>
            <div className="shop-sofa-size-slider">
              <Swiper
                slidesPerView={1}
                spaceBetween={12}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  998: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                    allowTouchMove: false,
                  },
                  1366: {
                    slidesPerView:
                      items?.items?.length < 4 ? items?.items?.length : 4,
                    spaceBetween: 12,
                    allowTouchMove: false,
                  },
                }}
              >
                {items?.items?.map((collection: any, index: number) => (
                  <SwiperSlide key={index}>
                    <CustomLink
                      href={collection.href}
                      className={styles.shopsofasizecard}
                    >
                      <figure style={{ background: '#f5f5f5' }}>
                        <WsStaticImage
                          quality={100}
                          src={collection?.image}
                          alt={collection.alt}
                          width={400}
                          height={500}
                          //// loading="lazy"
                          // style={{ height: '55%', margin: '40px auto 0' }}
                        />
                      </figure>
                      {collection.label ? (
                        typeof collection.label === 'object' ? (
                          <>
                            <p className={styles['col-name']}>
                              {collection.title}
                            </p>
                            <p
                              className={styles['price']}
                              style={{ textAlign: 'left' }}
                            >
                              {collection.label.price && (
                                <>
                                  {collection.label.price}{' '}
                                  <del className={styles.offprice}>
                                    {collection.label.mrp}
                                  </del>
                                  <span className={styles.discountoff}>
                                    {collection.label.discount}
                                  </span>
                                </>
                              )}
                            </p>
                            {collection.saletag && (
                              <p
                                className={styles.sale}
                                style={{
                                  background:
                                    collection.saletag === 'New'
                                      ? '#4caf50'
                                      : '#E27A34',
                                }}
                              >
                                {collection.saletag}
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            <p
                              className={styles['col-name']}
                              style={{ textAlign: 'center' }}
                            >
                              {collection.title}
                            </p>
                            <p
                              className={styles['price']}
                              style={{ textAlign: 'center' }}
                            >
                              {collection.label}
                            </p>
                          </>
                        )
                      ) : (
                        <p
                          className={styles['col-name']}
                          style={{ textAlign: 'center' }}
                        >
                          {collection.title}
                        </p>
                      )}
                    </CustomLink>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>
        {items.viewMore && (
          <div className={styles.modfurniturbottom}>
            <CustomLink
              href={items.viewMore}
              className={`${styles['btn-primary']} btn btn-primary`}
            >
              View All
              <WsStaticImage
                src={'/images/categories/view-all-link-arrow.svg'}
                alt="media"
                width={17}
                height={17}
                quality={75}
                //// loading="lazy"
              />
            </CustomLink>
          </div>
        )}
      </section>
    </>
  );
};

export default SeatingEssential;
