import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import React from 'react';
import styles from './style.module.scss';

interface SeatingEssentialProps {
  items: any;
}

const SeatingEssentialMobile: React.FC<SeatingEssentialProps> = ({ items }) => {
  return (
    <>
      <section className={styles.shopsofasizesection}>
        <div className={styles.shopsofasizeinner}>
          <div className="container-1240 px-sm-0">
            <div className="wdRow ">
              <div className="wdCol-lg-12">
                <div className={styles.shopsofasizeheading}>
                  <h2 className={styles['head-title']}>{items.title}</h2>
                  <p className={styles['sub-title']}>{items?.description}</p>
                </div>
              </div>
            </div>
            <div className={styles.shopsofasizecontant}>
              <div
                className={`${styles.shopbox} ${styles['grid-container-4-col']}`}
              >
                {items?.items?.map((collection: any, index: number) => (
                  <CustomLink
                    href={collection.href}
                    className={styles.shopsofasizecard}
                    key={index}
                  >
                    <figure>
                      <WsStaticImage
                        // quality={100}
                        src={collection?.image}
                        alt={collection.alt}
                        width={230}
                        height={199}
                        // loading="lazy"
                        sizes="100vw"
                      />
                    </figure>

                    {collection.label ? (
                      typeof collection.label === 'object' ? (
                        <>
                          <p className={styles['col-name']}>
                            {collection.title}
                          </p>
                          <p
                            className={styles['price']}
                            style={{ textAlign: 'left' }}
                          >
                            {collection.label.price && (
                              <>
                                {collection.label.price}{' '}
                                <del className={styles.offprice}>
                                  {collection.label.mrp}
                                </del>
                                <span className={styles.discountoff}>
                                  {collection.label.discount}
                                </span>
                              </>
                            )}
                          </p>
                          {collection.saletag && (
                            <p
                              className={styles.sale}
                              style={{
                                background:
                                  collection.saletag === 'New'
                                    ? '#4caf50'
                                    : '#E27A34',
                              }}
                            >
                              {collection.saletag}
                            </p>
                          )}
                        </>
                      ) : (
                        <>
                          <p
                            className={styles['col-name']}
                            style={{ textAlign: 'center' }}
                          >
                            {collection.title}
                          </p>
                          <p
                            className={styles['price']}
                            style={{ textAlign: 'center' }}
                          >
                            {collection.label}
                          </p>
                        </>
                      )
                    ) : (
                      <p
                        className={styles['col-name']}
                        style={{ textAlign: 'center' }}
                      >
                        {collection.title}
                      </p>
                    )}
                  </CustomLink>
                ))}
              </div>
            </div>
            {items.viewMore && (
              <div className={styles.viewmorebtndiv}>
                <CustomLink
                  href={items.viewMore}
                  className={`${styles['view-all-furni']} btn view-all-btn`}
                >
                  View More Categories
                  <WsStaticImage
                    src="/images/home-new1/ArrowRight.svg"
                    alt="ArrowRight"
                    width={24}
                    height={20}
                    quality={75}
                    //loading="lazy"
                  />
                </CustomLink>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default SeatingEssentialMobile;
