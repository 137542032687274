import WsStaticImage from '@/components/common/WsStaticImage';
import CustomLink from '@/components/CustomLink';
import dynamic from 'next/dynamic';
import React from 'react';
import styles from './style.module.scss';

const TextExpand = dynamic(() => import('@/components/common/TextExpand'), {
  loading: () => <div>Loading...</div>,
});

interface CategoryDescriptionProps {
  name?: any;
  items: any;
  isMobile: any;
}
const CategoryDescriptionMobile: React.FC<CategoryDescriptionProps> = ({
  items,
  isMobile,
}) => {
  const sofasets = items.items;
  const length = sofasets?.length;

  return (
    <>
      <section className={styles.sofasetsection}>
        <div className="container-1240">
          <div className="wdRow">
            <div className={`wdCol-lg-12 ${styles['sofaset-heading']}`}>
              {items?.title === 'Top Picks For You' ? (
                <span className={styles['title']}>{items?.title}</span>
              ) : (
                <h1 className={styles['title']}>{items?.title}</h1>
              )}
              {items.description && (
                <TextExpand content={items.description} isHtml />
              )}
            </div>
          </div>
          <div className={styles.sofasetcontent}>
            <div
              className={`wdRow wdRow-cols-6 wdRow-cols-lg-${(length % 2 ? length + 1 : length) / 2} wdgx-1 `}
            >
              {sofasets?.map((sofaset: any, index: number) => (
                <div className={`wdCol ${styles['category-card']}`} key={index}>
                  <CustomLink
                    href={sofaset.href}
                    prefetch={true}
                    className={styles.sofasetcard}
                  >
                    <div className={styles.sofasetimg}>
                      <figure>
                        <WsStaticImage
                          src={
                            isMobile && sofaset?.mimage
                              ? sofaset?.mimage
                              : sofaset?.image
                          }
                          alt={sofaset.title}
                          title={sofaset.title}
                          className={styles['img-card']}
                          quality={75}
                          width={245}
                          height={225}
                        />
                      </figure>
                    </div>
                    <div className={styles.sofasettext}>
                      <p className={styles['category-name']}>{sofaset.title}</p>
                    </div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryDescriptionMobile;
