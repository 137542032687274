import React from 'react';
import styles from './style.module.scss';
import { decodeHTMLEntities } from '@/utils/common';

interface CategoryTextProps {
  items?: any;
}

const CategoryText: React.FC<CategoryTextProps> = ({ items }) => {
  return (
    <>
      <section className={styles['store-text-section']}>
        <div className={styles['store-text-inner']}>
          <div className="container-fluid">
            <div
              className={styles['store-text-content']}
              dangerouslySetInnerHTML={{
                __html: decodeHTMLEntities(items), // Decodes and styles HTML content
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default CategoryText;
