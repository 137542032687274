import React from 'react';
import styles from './style.module.scss';
import WsFillImage from '@/components/common/WsFillImage';
import Description from './description';
import CustomLink from '@/components/CustomLink';

interface FinestBrandProps {
  items: any;
}
const FinestBrand: React.FC<FinestBrandProps> = ({ items }) => {
  return (
    <>
      <section className={styles.finestbrandsection}>
        <div className="container-fluid">
          <div className="wdRow ">
            <div className="wdCol-lg-12">
              <div className={styles.finestbrandheading}>
                <div className={styles.title}>
                  India's Finest<h1> Online Furniture</h1> Brand
                </div>
                {items?.description && (
                  <Description description={items?.description} />
                )}
              </div>
            </div>
          </div>
          <div className={styles.finestbrandcontent}>
            <div className="wdRow wdgx-lg-4 wdgx-md-3">
              {items?.items?.map((poster: any, index: number) => (
                <div
                  className="wdCol-lg-4 wdCol-md-4 wdCol-sm-6 wdCol-12"
                  key={index}
                >
                  <CustomLink
                    href={poster.href}
                    className={styles.finestbrandcard}
                    aria-label={poster.alt}
                  >
                    <div className={styles.finestbrandimg}>
                      <figure>
                        <WsFillImage
                          src={poster.image}
                          alt={poster.alt}
                          title={poster.alt}
                          //// loading="lazy"
                          quality={100}
                        />
                      </figure>
                    </div>
                  </CustomLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FinestBrand;
